<template>
  <footer>
      <div class="tg_footer">
        <div class="container">
          <ul class="text_footer_nsx">
            <li>
              <a href="http://www.kox-radio.jp/takeoff7/" target="_blank">会社概要</a>
            </li>
            <li>
              <router-link target="_blank" :to="{ name: 'term-user'}">利用規約</router-link>
            </li>
            <li>
              <router-link target="_blank" :to="{ name: 'pivacy-policy'}">プライバシーポリシー</router-link>
            </li>
          </ul>
          <p class="coy_right_ft">© 2021 SHIBUYA TAKE OFF 7. SHIBUYA TAKE OFF 7</p>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  name: "KTFooter",
};
</script>
