<template>
  <!-- begin:: Header -->
  <header>
    <div class="logo_site">
      <div class="container">
        <figure class="logo_left_img">
          <a target="_blank" href="http://www.kox-radio.jp/takeoff7/">
            <img :src="urlImage('/template/images/logo-left.png')" />
          </a>
        </figure>
        <figure class="logo_right_img">
          <a href="#">
            <img :src="urlImage('/template/images/logo-right.png')" />
          </a>
        </figure>
      </div>
    </div>
  </header>
  <!-- end:: Header -->
</template>

<script>
import { ENV } from "@/core/config/env";

export default {
  name: "KTHeader",
  data() {
    return {};
  },

  methods: {
    urlImage(img) {
      let url = `${ENV.PREFIX_FOLDER}` + img;
      return url;
    }
  }
};
</script>
