<template>
  <div>
    <KTHeader></KTHeader>
    <div id="tg_content">
      <router-view></router-view>
    </div>
    <KTFooter></KTFooter>
    <div class="popup_bos13" v-bind:class="{'popup':check}">
      <div class="content_popup">
        <h2 class="popup_title">予約番号: {{ detail_code }}</h2>
        <div class="form_group">
          <h3>予約がキャンセルしました。</h3>
          <span>「 Booking system」を利用して頂き、ありがとうございます。キャンセル料について、メールで送信しましたので、ご確認お願い致します。</span>
          <button type="submit" @click.prevent="closePopup()">閉じる</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTHeader from "@/view/layout/header/Header.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";

export default {
  name: "Layout",
  data(){
    return {
      check: false,
      detail_code: '',
    }
  },
  components: { KTHeader, KTFooter },
  beforeUpdate() {
    if(this.$route.params.flag && this.$route.params.code) {
      this.check = this.$route.params.flag;
      this.detail_code = this.$route.params.code;
    }
  },
  methods :{
    closePopup() {
      this.check = false
      this.$route.params.flag = false
    }
  }

};
</script>
<style scoped lang="scss">
.popup_bos13 {
  display: none;
}
</style>